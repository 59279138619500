import React from "react"
import styled from "styled-components";
import { only } from 'styled-breakpoints';
import BlogBox from '../BlogBox';

const Root = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 6rem 5%;
  padding: 50px 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${only('mobile')} {
    grid-template-columns: 1fr 1fr;
  };

  ${only('mini')} {
    grid-template-columns: 1fr;
    padding: 0;
  };
`;

const BlogsList = ({ blogs }) => (
  <Root>
    {blogs.map((blog) => <BlogBox {...blog} key={blog.url} />)}
  </Root>
);

export default BlogsList;
