import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';
import { Text } from 'src/components/Common';
import { fadeInUp } from 'src/style/animations';
import { useInView } from 'react-intersection-observer';
import { only, up } from 'styled-breakpoints';
import { removeLinkStyle } from '../../../style/common';

const Image = styled.img`
  width: 100%;
  transition: all 0.2s;
`;

const Header = styled(Text)`
  margin-bottom: 10px;
  transition: all 0.2s;
`;

const Root = styled.li`
  ${removeLinkStyle}
  height: 100%;
  visibility: hidden;

  ${(props) =>
    props.show &&
    css`
      visibility: visible;
      ${fadeInUp};
    `};

  ${up('laptop')} {
    &:nth-child(3n + 2) {
      animation-delay: 150ms;
    }
    &:nth-child(3n + 3) {
      animation-delay: 300ms;
    }
  }

  ${only('mobile')} {
    &:nth-child(2n) {
      animation-delay: 150ms;
    }
  }

  &:hover {
    ${Image} {
      opacity: 0.85;
    }

    ${Header} {
      color: var(--primary);
    }
  }
`;

const Content = styled.article`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const BlogBox = ({ title, image, url, description }) => {
  const [show, setShow] = useState(false);
  const { ref: inViewRef, inView } = useInView();

  useEffect(() => {
    if (inView) {
      setShow(true);
    }
  }, [inView]);

  return (
    <Root ref={inViewRef} key={`BlogBox-${title}-${show}`} show={show}>
      <Link to={url}>
        <Content>
          <ImageWrapper>
            <Image src={image} alt={image} />
          </ImageWrapper>
          <Texts>
            <Header
              text={title}
              variant="h5"
              isMedium
              color="lighDark"
              sx={{ lineHeight: '38px' }}
              component="h3"
            />
            <Text text={description} variant="body2" color="lighDark" />
          </Texts>
        </Content>
      </Link>
    </Root>
  );
};

export default BlogBox;
