import * as React from "react"
import styled from "styled-components";
import BlogsSoldier from '/src/assets/soldiers/blogs_soldier.svg';
import { MiddleLayout, TopSection } from 'src/components/Common';
import { SEO } from '../Common';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { useLocation } from "@reach/router"
import { BLOGS } from '../../blogs';
import BlogsList from './BlogsList';
import { only } from 'styled-breakpoints';

const Root = styled.main`
  ${only('mini')} {
    padding-bottom: 30px;
  };
`;

const StyledMiddleLayout = styled(MiddleLayout)`
  margin-top: -25px;
`;

const Blogs = () => {
  const { language, navigate } = useI18next();
  const { t } = useTranslation();
  const { href } = useLocation();

  const breadcrumbsElementsSchema = [{
    "@type": "ListItem",
    "position": 2,
    "name": t("Blogs"),
    "item": href
  }];

  if (language === 'en') {
    // navigate('/');
    // return null;
  }

  return (
    <Root>
      <SEO
        title="SEO title blogs"
        description="SEO description blogs"
        breadcrumbsElementsSchema={breadcrumbsElementsSchema}
        hideAlternateEN
      />
      <TopSection
        header="Blogs page title"
        description="Our blogs description"
        Image={BlogsSoldier}
        size="small"
      />
      <StyledMiddleLayout>
        <BlogsList blogs={BLOGS} />
      </StyledMiddleLayout>
    </Root>
  );
};

export default Blogs;
