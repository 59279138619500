import { URL } from '../url';

export const ReactVsAngular = {
  title: 'React VS Angular',
  description: 'React VS Angular description',
  url: URL.REACT_VS_ANGULAR_BLOG,
  image: '/images/blogs/react-vs-angular/box.jpg',
};

export const WebDevelopmentProcesses = {
  title: 'Web development processes',
  description: 'Web development processes description',
  url: URL.WEB_DEVELOPMENT_PROCESSES_BLOG,
  image: '/images/blogs/web-development/box.jpg',
};

export const DesignRules = {
  title: '6 Design rules',
  description: '6 Design rules description',
  url: URL.DESIGN_RULRS_BLOG,
  image: '/images/blogs/design-rules/box.jpg',
};

export const EmphasisToConsiderWhenDesigningSaaSApp = {
  title: 'Emphasis to consider when designing saas app',
  description: 'Emphasis to consider when designing saas app description',
  url: URL.EMPHASIS_TO_CONSIDER_WHEN_DESIGNING_SAAS_APP_BLOG,
  image: '/images/blogs/saas-principles/box.jpg',
};

export const BenefitsOfWorkingWithSoftwareCompany = {
  title: 'Benefits of working with software company',
  description: 'SEO description benefits of working with software company',
  url: URL.BENEFITS_OF_WORKING_WITH_SOFTWARE_COMPANY_BLOG,
  image: '/images/blogs/benefits-of-working-with-software-company/box.jpg',
};

export const SolutionsForSoftwareDevelopment = {
  title: 'Solutions for software development',
  description: 'SEO description solutions for software development',
  url: URL.SOLUTIONS_FOR_SOFTWARE_DEVELOPMENT_BLOG,
  image: '/images/blogs/solutions-for-software-development/box.jpg',
};

export const HowToDoSoftwareDesignRight = {
  title: 'How to do software design right',
  description: 'SEO description how to do software design right',
  url: URL.HOW_TO_DO_SOFTWARE_DESIGN_RIGHT_BLOG,
  image: '/images/blogs/how-to-do-software-design-right/box.jpg',
};

export const StagesOnAgileDevelopment = {
  title: 'Stages on agile development',
  description: 'SEO description stages on agile development',
  url: URL.STAGES_ON_AGILE_DEVELOPMENT_BLOG,
  image: '/images/blogs/stages-on-agile-development/box.jpg',
};

export const LeadingTrendsInWebApplicationDevelopment = {
  title: 'Leading Trends In Web Application Development',
  description: 'SEO description Leading Trends In Web Application Development',
  url: URL.LEADING_TRENDS_IN_WEB_APPLICATION_DEVELOPMENT,
  image: '/images/blogs/leading-trends-in-web-application-development/box.jpg',
};

export const SoftwareDevelopmentToolsThatMustBeFamiliar = {
  title: 'Software Development Tools That Must Be Familiar',
  description:
    'SEO description Software Development Tools That Must Be Familiar',
  url: URL.SOFTWARE_DEVELOPMENT_TOOLS_THAT_MUST_BE_FAMILIAR,
  image: '/images/blogs/software-development-tools-that-must-be-familiar/box.jpg',
};

export const WinningSitesBuiltUsingReact = {
  title: 'Winning Sites Built Using React',
  description: 'SEO description Winning Sites Built Using React',
  url: URL.WINNING_SITES_BUILT_USING_REACT,
  image: '/images/blogs/winning-sites-built-using-react/box.jpg',
};

export const FrequentlyAskedQuestionsAndExamplesOfSaas = {
  title: 'Frequently Asked Questions And Examples Of Saas',
  description:
    'SEO description Frequently Asked Questions And Examples Of Saas',
  url: URL.FREQUENTLY_ASKED_QUESTIONS_AND_EXAMPLES_OF_SAAS,
  image: '/images/blogs/frequently-asked-questions-and-examples-of-saas/box.jpg',
};

export const TypicalFrontEndDay = {
  title: 'Typical Front End Day',
  description:
    'SEO description Typical Front End Day',
  url: URL.TYPICAL_FRONT_END_DAY,
  image: '/images/blogs/typical-front-end-day/box.jpg',
}

export const WhatDoseFullstackDeveloper = {
  title: 'What Dose Full Stack Developer',
  description:
    'SEO description What Dose Full Stack Developer',
  url: URL.WHAT_DOSE_FULL_STACK_DEVELOPER,
  image: '/images/blogs/what-dose-full-stack-developer/box.jpg',
}

export const BLOGS = [
  WinningSitesBuiltUsingReact,
  StagesOnAgileDevelopment,
  SolutionsForSoftwareDevelopment,
  BenefitsOfWorkingWithSoftwareCompany,
  ReactVsAngular,
  EmphasisToConsiderWhenDesigningSaaSApp,
  LeadingTrendsInWebApplicationDevelopment,
  WebDevelopmentProcesses,
  WhatDoseFullstackDeveloper,
  SoftwareDevelopmentToolsThatMustBeFamiliar,
  TypicalFrontEndDay,
  FrequentlyAskedQuestionsAndExamplesOfSaas,
  HowToDoSoftwareDesignRight,
  DesignRules,
];
